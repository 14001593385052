import React, { useState } from "react"
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  CssBaseline,
  IconButton,
  Toolbar,
  Drawer as MuiDrawer,
  DrawerProps,
  Divider,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material"
import {
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
  Refresh as RefreshIcon,
  Close as CloseIcon,
} from "@mui/icons-material"
import { styled, Theme, CSSObject } from "@mui/material/styles"
import { observer } from "mobx-react-lite"

import { isMobile } from "../tools"
import { TopNotification, useBaseStores } from "../stores"
import Snack from "./Snack"
import ModalDialog from "./ModalDialog"
import UserButton from "./UserButton"
import Breadcrumbs from "./Breadcrumbs"

const drawerWidth = 180
const drawerHeight = 48
const topNotificationHeight = 48

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: "rgb(74,85,104)",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

type DrawerHeaderProps = {
  bgColor?: string
  children?: React.ReactNode
}

export const DrawerHeader = observer((props: DrawerHeaderProps) => {
  const { uiStore } = useBaseStores()
  const showNotification =
    uiStore.topNotification && !uiStore.topNotification.closedAt

  return (
    <>
      {showNotification && <div style={{ height: topNotificationHeight }} />}
      <DrawerHeader1 {...props} />
    </>
  )
})

export const DrawerHeader1 = styled("div", {
  shouldForwardProp: (prop) => prop !== "bgColor",
})<DrawerHeaderProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // nescessary for content to be below app bar
  minHeight: drawerHeight,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "top",
})<DrawerProps & { top?: number | string }>(({ theme, open, top }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": { ...openedMixin(theme), top },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": { ...closedMixin(theme), top },
  }),
}))

const Iconbar = () => {
  // eslint-disable-next-line no-null/no-null
  return null
}

const Progress = observer(() => {
  const { uiStore } = useBaseStores()
  return (
    <Box
      sx={{
        w: 6,
        textAlign: "center",
        mr: 2,
      }}
    >
      {uiStore.loading > 0 ? (
        <IconButton color="inherit">
          <CircularProgress color="inherit" size={24} disableShrink />
        </IconButton>
      ) : uiStore.refreshFunc ? (
        <IconButton color="inherit" onClick={uiStore.refreshFunc}>
          <RefreshIcon />
        </IconButton>
      ) : // eslint-disable-next-line no-null/no-null
      null}
      <UserButton />
    </Box>
  )
})

export const Shell = observer(
  ({
    sideMenu,
    logo,
    children,
  }: {
    sideMenu?: React.ReactElement
    logo?: React.ReactElement
    children?: React.ReactChild | React.ReactChild[]
  }) => {
    const { uiStore } = useBaseStores()
    const [open, setOpen] = useState(!isMobile())

    const handleDrawerOpen = () => {
      setOpen(true)
    }
    const handleDrawerClose = () => {
      setOpen(false)
    }
    const handleNotificationClose = () => {
      uiStore.setTopNotification({
        ...uiStore.topNotification!,
        closedAt: new Date(),
      })
    }

    const showNotification =
      uiStore.topNotification && !uiStore.topNotification.closedAt

    return (
      <>
        {showNotification && (
          <TopNotificationView
            value={uiStore.topNotification!}
            onClose={handleNotificationClose}
          />
        )}
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            sx={[!!showNotification && { top: topNotificationHeight }]}
          >
            <Toolbar variant="dense" disableGutters>
              <IconButton
                color="inherit"
                edge="start"
                onClick={handleDrawerOpen}
                sx={{
                  marginRight: 1,
                  ml: {
                    xs: 1,
                    sm: 2,
                  },
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>

              <Breadcrumbs />
              <Progress />
              <Iconbar />
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader bgColor="rgb(74,85,104)">
              <a href="#/" style={{ textDecoration: "none" }}>
                {logo}
              </a>
              <IconButton
                onClick={handleDrawerClose}
                style={{ color: "white" }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              {sideMenu && React.cloneElement(sideMenu, { open })}
              <Typography display="fixed" bottom={0}>
                Version:&nbsp;
                {(import.meta.env.VITE_APP_VERSION as string | undefined) || ""}
              </Typography>
            </Box>
          </Drawer>
          <Box component="main" flexGrow={1}>
            <div style={{ height: drawerHeight }} />
            {children}
          </Box>
          <Snack />
          <ModalDialog />
        </Box>
      </>
    )
  }
)

const TopNotificationView = ({
  value,
  onClose,
}: {
  value: TopNotification
  onClose: () => void
}) => {
  return (
    <>
      <Grid
        container
        sx={{
          zIndex: 2000,
          height: topNotificationHeight,
          backgroundColor: value.kind === "error" ? "#ff4d4d" : "yellow",
          position: "fixed",
        }}
        alignItems="center"
      >
        <Grid item xs style={{ paddingLeft: 10 }}>
          <span
            style={{ color: value.kind === "error" ? "white" : "black" }}
            dangerouslySetInnerHTML={{ __html: value.html }}
          />
        </Grid>

        {value.kind !== "error" && (
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <div style={{ height: drawerHeight }} />
    </>
  )
}
